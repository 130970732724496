import React from 'react';
import Loader from '../loader';
import useExternalLoading from './useExternalLoading';

export default function Index() {
  const isLoading = useExternalLoading();

  if (!isLoading) return null;

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
        fontSize: '24px',
        zIndex: 1000,
      }}
    >
      <Loader />
    </div>
  );
}
