import {useEffect, useState} from 'react';
import loadingStore from './loadingStore';

const useExternalLoading = () => {
  const [isLoading, setIsLoading] = useState(loadingStore.get());

  useEffect(() => {
    const unsubscribe = loadingStore.subscribe(() =>
      setIsLoading(loadingStore.get())
    );
    return () => unsubscribe();
  }, []);

  return isLoading;
};

export default useExternalLoading;
