const createExternalStore = () => {
  let isLoading = false;
  let listeners = [];

  return {
    get: () => isLoading,
    set: value => {
      if (isLoading !== value) {
        isLoading = value;
        listeners.forEach(listener => listener());
      }
    },
    subscribe: listener => {
      listeners.push(listener);
      return () => {
        listeners = listeners.filter(l => l !== listener);
      };
    },
  };
};

const loadingStore = createExternalStore();

export default loadingStore;
