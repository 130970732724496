import _startsWith from 'lodash/startsWith';

const utils = {
  isProduction: process.env.REACT_APP_ENV === 'PROD',
  isStage:
    process.env.REACT_APP_ENV === 'PROD' &&
    _startsWith(process.env.REACT_APP_URL, 'https://stage.'),
  isDemo: process.env.REACT_APP_ENV === 'DEMO',
  isDevelopment: process.env.REACT_APP_ENV === 'DEV',
  stripeKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
};

export default utils;
